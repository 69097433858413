body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
}
.center{
  text-align: center;
}

.d-content {
  padding-top: 80px;
}

.h-inner {
  position: relative;
  /* width: 76.875%; */
  width: 914px;
  height: 54px;
  margin: 0 auto;
}

.footer { 
  background-color: #ebebeb;
  /* height: 124px; */
  border-top: 1px solid #dbdbdb;
  color: #777777;
  font-size: 14px;
  /* text-align: center */
}
.footer.fixed {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}

.useradjest {
  /* padding: 15px; */
  position: relative
}

.useradjest a {
  color: #777
}

.useradjest::after {
  position: absolute;
  content: "";
  height: 16px;
  top: 50%;
  right: 0;
  margin-top: -8px;
  width: 1px;
  background-color: #777
}
.useradjest:last-child::after{
  display: none;
}

.copy-right {
  padding-top: 10px
}

.btn{
  padding: 8px 10px;
  border-radius: 5px;
  border:none;
}
.btn-green{
  background-color: #30592c;
  color: #fff;
  width: 80px;
}
.trsc{
  margin-left: 50%;
  transform: translateX((-50%));
}
.poic{
   left: 50%;
  transform: translateX((-50%));
}
.jc_spca{justify-content: space-around;}
.jc_spcb{justify-content: space-between;}
.jc_fe{justify-content: flex-end;}
.jc_c{justify-content: center;}
.lt-spc2{letter-spacing: 0.125rem;}
.flex{
  display: flex;
  flex-wrap: wrap;
}
.cr{color: red;}
.alc{align-items: center;}
.mt30{margin: 2rem 0;}
.pt20{padding: 1.3rem 0;} 
.mt45{margin: 2.8rem 0;}
.mt60{margin: 3.75rem 0;}
.mt24{margin: 24px 0;}
.mt10{margin: 10px 0;}
.mt20{margin: 1.2rem 0;}
.mt16{margin-top: 16px;}
.ml20{margin: 0 1.2rem;}
.mr10{margin-right: 10px;}
.ml10{margin-left: 10px;}
.hideWord{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.maxs{margin: 5px;}
.mbsm{margin-bottom: 10px;}
.f40{
  font-size: calc(1.7rem + 10px);
  font-weight: 600;
}
.f16{font-size: calc(0.6rem + 8px);}
.f26{
  font-size: calc(0.6rem + 13px);
  font-weight: 600;
}
 
.fw5{font-weight: 500;}
.fw6{font-weight: 600;}

.auto{margin: 0 auto;}
.fwl{font-weight: lighter;}
/* .code{
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
} */
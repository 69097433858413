
.header {
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  z-index: 100
}

.h-inner {
  position: relative;
  /* width: 76.875%; */
  width: 914px;
  height: 54px;
  margin: 0 auto;
}

.logo { 
  cursor: pointer;
  position: absolute;
  top: 0.8rem;
  left: 7rem;
  z-index: 3;
}

.logo a {
  display: block;
  width: 100%;
  height: 100%;
  /* background: url(../img/logo_white.png) no-repeat 0 0; */
  background-size: 72px 32px
}
.header.scrollTop {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ebebeb
}

.scrollTop .logo a {
  /* background: url(../img/logo.png) no-repeat 0 0; */
  display: block;
  width: 100%;
  height: 100%;
  background-size: 72px 32px
}
 

.hide {
  display: none
}

.nav {
  position: absolute;
  left: 0;
  width: 100%;
  height: 54px;
  /* overflow: hidden; */
  font-size: 24px;
}

.nav a {
  /* color: #777777 */
  color: #fff;
  text-decoration: none;
}

.scrollTop .nav a {
  color: #333
}

.nav-ul {
  text-align: center;
  font-size: 0
}

.nav-item {
  padding: 0 38px;
  display: inline-block;
  height: 54px;
}

.nav-item a {
  font-size: 18px;
  display: inline-block;
  line-height: 54px;
  height: 50px;
  padding: 0 16px;
  border-bottom: 4px solid transparent
}

.nav-item .active {
  border-bottom-color: #f5f5f5
}

.nav-item a:hover {
  /* border-bottom-color: #f5f5f5; */
  background-color: rgba(0, 0, 0, 0.06)
}

.scrollTop .nav-item .active {
  color: #010523
}
.scrollTop .logo span{
  color: #010523
}
.scrollTop .down a{
  color: #fff!important;
}
.scrollTop .down{
  background-color: #010523;
}
.scrollTop .nav-item .active {
  color: #010523;
  border-bottom-color: #010523
}

.scrollTop .nav-item a:hover {
  /* border-bottom-color: #010523 */
  /* color: #010523 */
  background-color: rgba(0, 0, 0, 0.06)
}


@media screen and (max-width:1366px) {
  .h-inner {
    height: 46px;
}
.nav {
    height: 46px;
}
.nav-item {
    height: 46px;
}
.nav-item a {
    height: 46px;
    line-height: 42px;
}
.scrollTop .h-inner {
    height: 46px;
}
.scrollTop .nav {
    height: 46px;
}
.scrollTop .nav-item {
    height: 46px;
}
.scrollTop .nav-item a {
    height: 46px;
    line-height: 42px;
}
.logo {
    margin-top: 4px;
}
.logo a {
    background-size: auto 28px;
}
.scrollTop .logo a {
    background-size: auto 28px;
}
.nav-item .active {
    height: 45px;
    line-height: 42px;
}
.scrollTop .nav-item .active {
    height: 45px;
    line-height: 42px;
}
.nav-item a:hover {
    /* border-bottom-color: #f5f5f5; */
    background-color: rgba(0, 0, 0, 0.06);
    height: 45px;
}
.nav-item a {
    font-size: 16px;
}
.scrollTop .nav-item .active {
    border-width: 2px;
}
.nav-item .active {
    border-width: 2px;
}
.nav-item {
    padding: 0 30px;
}
}


@media screen and (min-width:1920px) and (max-width:2560px) {
  .h-inner {
      height: 60px;
  }
  .nav {
      height: 60px;
  }
  .nav-item {
      height: 60px;
  }
  .nav-item a {
      height: 60px;
      line-height: 60px;
  }
  .scrollTop .h-inner {
      height: 60px;
  }
  .scrollTop .nav {
      height: 60px;
  }
  .scrollTop .nav-item {
      height: 60px;
  }
  .scrollTop .nav-item a {
      height: 60px;
      line-height: 56px;
  } 
  .logo a {
      background-size: auto 36px;
  }
  .scrollTop .logo a {
      background-size: auto 36px;
  }
  .nav-item .active {
      height: 57px;
      line-height: 56px;
  }
  .scrollTop .nav-item .active {
      height: 57px;
      line-height: 56px;
  }
  .nav-item a:hover {
      /* border-bottom-color: #f5f5f5; */
      background-color: rgba(0, 0, 0, 0.06);
      height: 57px;
  }
  .nav-item a {
      font-size: 20px;
  }
  .scrollTop .nav-item .active {
      border-width: 4px;
  }
  .nav-item .active {
      border-width: 4px;
  }
  .nav-item {
      padding: 0 38px;
  }
}



@media screen and (-webkit-resolution: 2dppx) {
  .logo a {
      display: block;
      width: 100%;
      height: 100%;
      /* background: url(/img/logo_white2x.png) no-repeat 0 0; */
      background-size: 72px 32px
  }
  .scrollTop .logo a {
      /* background: url(/img/logo2x.png) no-repeat 0 0; */
      display: block;
      width: 100%;
      height: 100%;
      background-size: 72px 32px
  }
}

.footer.fixed {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
}
.down{
  position: absolute;
  right: 7rem;
  top: 10px;
  background-color: #fff;
  width: 8.9rem;
  height: 2.5rem;
  border-radius: 2rem;
}
.toast{
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 6px;
  padding: 10px 20px;
  z-index: 999;
  display: flex;
  align-items: center;
}  
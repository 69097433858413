

.a-download {
  width: 930px;
  margin: 0 auto;
  height: 450px;
}

.down-ietm {
  width: 450px;
  float: left;
  height: 450px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}



.itemlogo {
  padding-top: 80px;
  height: 114px;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
}

.itemlogo.animation {
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -o-transform: translateY(20px);
  transform: translateY(20px);
}

.windows .itemlogo {
  background: url(./windows.png) no-repeat center 80px;
  background-size: auto 114px;
}

.mac .itemlogo {
  background: url(./mac.png) no-repeat center 80px;
  background-size: auto 114px;
}


.windows.hover .itemlogo {
  background: url(./windows-hover.png) no-repeat center 80px;
  background-size: auto 114px;
}




.d-item-title {
  /* padding-top: 84px; */
  line-height: 34px;
  font-size: 28px;
  color: #333;
  text-align: center
}

.d-item-desc {
  line-height: 28px;
  font-size: 20px;
  color: #333;
  text-align: center
}

.down-btn {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 16px;
}

.scan {
  display: none;
  text-align: center;
  font-size: 20px;
  color: #333;
}

.hover .scan {
  display: block;
  padding-top: 20px;
  padding-bottom: 16px;
  line-height: 40px;
}


.hover .down-btn {
  display: none
}

.hover.windows .down-btn {
  display: block
}


.down-btn a,.down-btn div {
  display: inline-block;
  width: 120px;
  height: 40px;
  border-radius: 2px;
  background-color: #0188fb;
  line-height: 40px;
  color: #fff;
  font-size: 20px;
}

.down-btn a:hover {
  background-color: #289cff;
}

.down-btn a:active {
  background-color: #0081ef;
}

.d-item-log {
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  padding-top: 2px;
}

.d-item-log a {
  color: #0188fb
}

.d-item-log a:hover {
  text-decoration: underline
}

.macqq {
  font-size: 20px;
  color: #333;
  text-align: center;
  padding-top: 54px;
  padding-bottom: 62px;
  line-height: 50px;
}

.macqq a {
  color: #0188fb
}

.macqq a:hover {
  text-decoration: underline
}

.down-ietm:hover {
  background: url(./01.png) no-repeat center 0;
  background-size: cover;
  -moz-box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.12);
}


/* .footer.d-page {
  position: fixed;
  bottom: 0;
  width: 100%;
} */

.d-item-desc .update-time {
  padding-left: 8px
}

@media screen and (min-width:1920px) and (max-width:2560px) {
  .a-download {
      width: 1242px;
      height: 604px;
  }
  .down-ietm {
      width: 621px;
      height: 574px;
  }
}

@media screen and (min-width:1440px) and (max-width:1920px) {
  .a-download {
      width: 1080px;
      height: 544px;
  }
  .down-ietm {
      width: 540px;
      height: 544px;
  }
}


/* @media screen and (max-width:1440px) {
  .a-download {
      width: 1080px;
      height: 574px;
  }
  .down-ietm {
      width: 360px;
      height: 574px;
  }
} */

.down-ietm-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.logo-area {
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 50%;
}

.info-area {
  position: absolute;
  top: 50%;
  bottom: 0;
  width: 100%;
}


@media screen and (max-width:1366px) {
  .a-download {
      transform: scale(0.9, 0.9)
  }
  .windows .itemlogo {
      background: url(./windows.png) no-repeat center 60px;
      background-size: auto 100px;
  }
  .mac .itemlogo {
    background: url(./mac.png) no-repeat center 60px;
    background-size: auto 100px;
}

  .windows.hover .itemlogo {
      background: url(./windows-hover.png) no-repeat center 60px;
      background-size: auto 100px;
  }
 
  .itemlogo {
      padding-top: 60px;
      height: 100px;
      -webkit-transition: transform 0.5s;
      -moz-transition: transform 0.5s;
      -moz-transition: transform 0.5s;
      -o-transition: transform 0.5s;
      transition: transform 0.5s;
  }
  body .d-content {
      padding-top: 0;
  }
  .macqq {
      padding-top: 20px;
  }
  .d-item-title {
      font-size: 24px;
  }
}

@media screen and (min-width:1367px) and (max-width:1919px) {
  .a-download {
      transform: scale(0.95, 0.95)
  }
  .windows .itemlogo {
      background: url(./windows.png) no-repeat center 60px;
      background-size: auto 114px;
  }
  

  .mac .itemlogo {
    background: url(./mac.png) no-repeat center 60px;
    background-size: auto 114px;
}

  .windows.hover .itemlogo {
      background: url(./windows-hover.png) no-repeat center 60px;
      background-size: auto 114px;
  }
 
  .itemlogo {
      padding-top: 60px;
      height: 114px;
      -webkit-transition: transform 0.5s;
      -moz-transition: transform 0.5s;
      -moz-transition: transform 0.5s;
      -o-transition: transform 0.5s;
      transition: transform 0.5s;
  }
}

@media screen and (min-width:1920px) and (max-width:2559px) {}

@media screen and (min-width:2560px) {}
.modalContent{
  width: 500px;
  height: 200px;
  background-color: #ffffff;
  position: absolute;
  left:50%;
  top: 200px;
  padding: 20px;
  transform: translateX(-50%);
  z-index: 99999;
  border-radius: 10px;
}
.closeModal{
  position: absolute;
  right: 20px;
  top: 0;
  font-size: 35px;
  cursor: pointer;
}